import { useEffect } from 'react';
import { animated, useTransition } from '@react-spring/web';

interface SearchConfirmDialogProps {
  placeName: string;
  searchTerm: string;
  onConfirm: (useLocation: boolean) => void;
  onClose: () => void;
}

export function SearchConfirmDialog({ 
  placeName, 
  searchTerm, 
  onConfirm, 
  onClose 
}: SearchConfirmDialogProps) {
  const transitions = useTransition(true, {
    from: { opacity: 0, transform: 'scale(0.9)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.9)' },
    config: { tension: 280, friction: 60 }
  });

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  return transitions((style, item) =>
    item && (
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <div 
          className="fixed inset-0 bg-black/50 backdrop-blur-sm"
          onClick={onClose}
        />
        <animated.div
          style={style}
          className="bg-white rounded-lg shadow-xl w-full max-w-md relative"
        >
          <div className="p-6">
            <h3 className="text-lg font-semibold mb-4">
              I found {placeName}
            </h3>
            <p className="text-gray-600 mb-6">
              What would you like to explore?
            </p>
            <div className="space-y-3">
              <button
                onClick={() => onConfirm(true)}
                className="w-full px-4 py-2 bg-[#346DFF] hover:bg-[#2855CC] text-white rounded-md transition-colors"
              >
                Show me {placeName}
              </button>
              <button
                onClick={() => onConfirm(false)}
                className="w-full px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-md transition-colors"
              >
                Find {searchTerm}-related places nearby
              </button>
            </div>
          </div>
        </animated.div>
      </div>
    )
  );
}
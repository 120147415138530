import { useState, useRef, useEffect } from 'react';
import { playLocationStory } from '../services/api';
import type { Location } from '../types';

export type StoryState = 'idle' | 'generating-story' | 'generating-audio' | 'ready';

interface UseStoryPlayerProps {
  location: Location;
  onStoryGenerated: (locationId: string, story: string, audioUrl: string) => void;
  onStoryStateChange: (state: StoryState, story?: string) => void;
}

export function useStoryPlayer({ 
  location, 
  onStoryGenerated,
  onStoryStateChange 
}: UseStoryPlayerProps) {
  const [state, setState] = useState<StoryState>('idle');
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const storyRef = useRef<string | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const loadingRef = useRef<boolean>(false);
  const currentBlobUrlRef = useRef<string | null>(null);
  const errorTimeoutRef = useRef<number>();

  const showError = (message: string) => {
    setError(message);
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }
    errorTimeoutRef.current = window.setTimeout(() => setError(null), 3000);
  };

  const cleanupAudio = () => {
    if (audioRef.current) {
      try {
        const audio = audioRef.current;
        audio.pause();
        setIsPlaying(false); // Ensure isPlaying is reset when cleaning up
        audio.removeAttribute('src');
        audio.load();
      } catch (e) {
        console.warn('Error cleaning up audio:', e);
      }
    }
    
    if (currentBlobUrlRef.current) {
      try {
        URL.revokeObjectURL(currentBlobUrlRef.current);
        currentBlobUrlRef.current = null;
      } catch (e) {
        console.warn('Error revoking blob URL:', e);
      }
    }
  };

  useEffect(() => {
    // Initialize audio element if it doesn't exist
    if (!audioRef.current) {
      audioRef.current = new Audio();
    }

    const audio = audioRef.current;

    const handlePlay = () => {
      console.log('Audio play event');
      setIsPlaying(true);
    };
    
    const handlePause = () => {
      console.log('Audio pause event');
      setIsPlaying(false);
    };
    
    const handleEnded = () => {
      console.log('Audio ended event');
      setIsPlaying(false);
      audio.currentTime = 0;
    };

    const handleError = (e: Event) => {
      if (loadingRef.current) return;
      
      const audioError = (e.target as HTMLAudioElement).error;
      let errorMessage = 'Audio playback failed. Please try again.';
      
      if (audioError) {
        switch (audioError.code) {
          case MediaError.MEDIA_ERR_ABORTED:
            errorMessage = 'Audio playback was aborted. Please try again.';
            break;
          case MediaError.MEDIA_ERR_NETWORK:
            errorMessage = 'Network error occurred. Please check your connection.';
            break;
          case MediaError.MEDIA_ERR_DECODE:
            errorMessage = 'Audio decoding failed. Please try again.';
            break;
          case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
            errorMessage = 'Audio format not supported. Please try again.';
            break;
        }
      }

      console.error('Audio error:', audioError);
      setIsPlaying(false);
      setState('idle');
      showError(errorMessage);
      cleanupAudio();
    };

    audio.addEventListener('play', handlePlay);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('error', handleError);

    return () => {
      audio.removeEventListener('play', handlePlay);
      audio.removeEventListener('pause', handlePause);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('error', handleError);
      cleanupAudio();
      
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, [location.id]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      cleanupAudio();
      
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setState('idle');
    storyRef.current = null;
    setError(null);
    setIsPlaying(false); // Reset playing state when location changes
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    cleanupAudio();
    onStoryStateChange('idle');
  }, [location.id, location.timeframe, location.topics, location.storyStyle]);

  const handlePlayPause = async () => {
    if (location.audioUrl && audioRef.current) {
      if (audioRef.current.paused) {
        try {
          if (!currentBlobUrlRef.current) {
            loadingRef.current = true;
            setState('generating-audio');
            const response = await fetch(location.audioUrl);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = await response.blob();
            currentBlobUrlRef.current = URL.createObjectURL(blob);
            audioRef.current.src = currentBlobUrlRef.current;
            
            await new Promise<void>((resolve, reject) => {
              if (!audioRef.current) return reject(new Error('Audio element not found'));
              
              const handleCanPlay = () => {
                audioRef.current?.removeEventListener('canplaythrough', handleCanPlay);
                audioRef.current?.removeEventListener('error', handleError);
                resolve();
              };
              
              const handleError = (e: Event) => {
                audioRef.current?.removeEventListener('canplaythrough', handleCanPlay);
                audioRef.current?.removeEventListener('error', handleError);
                reject(new Error('Failed to load audio'));
              };

              audioRef.current.addEventListener('canplaythrough', handleCanPlay, { once: true });
              audioRef.current.addEventListener('error', handleError, { once: true });
              audioRef.current.load();
            });
            
            loadingRef.current = false;
            setState('ready');
          }
          
          await audioRef.current.play();
          setIsPlaying(true); // Explicitly set playing state when starting playback
        } catch (error) {
          loadingRef.current = false;
          setState('idle');
          if (error instanceof Error && error.name !== 'AbortError') {
            console.error('Error playing audio:', error);
            setIsPlaying(false);
            showError('Failed to play audio. Please try again.');
            cleanupAudio();
          }
        }
      } else {
        audioRef.current.pause();
        setIsPlaying(false); // Explicitly set playing state when pausing
      }
      return;
    }

    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      setError(null);

      setState('generating-story');
      onStoryStateChange('generating-story');

      if (abortControllerRef.current.signal.aborted) {
        return;
      }

      const { story, audioUrl } = await playLocationStory(
        location,
        (stage, content) => {
          if (stage === 'story' && content) {
            storyRef.current = content;
            setState('generating-audio');
            onStoryGenerated(location.id, content, '');
            onStoryStateChange('generating-audio', content);
          } else if (stage === 'audio') {
            setState('generating-audio');
            onStoryStateChange('generating-audio');
          }
        }
      );

      loadingRef.current = true;
      cleanupAudio();
      
      try {
        if (!audioRef.current) {
          audioRef.current = new Audio();
        }

        const response = await fetch(audioUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        currentBlobUrlRef.current = URL.createObjectURL(blob);
        
        const audio = audioRef.current;
        audio.src = currentBlobUrlRef.current;
        
        await new Promise<void>((resolve, reject) => {
          const handleCanPlay = () => {
            audio.removeEventListener('canplaythrough', handleCanPlay);
            audio.removeEventListener('error', handleError);
            resolve();
          };
          
          const handleError = (e: Event) => {
            audio.removeEventListener('canplaythrough', handleCanPlay);
            audio.removeEventListener('error', handleError);
            reject(new Error('Failed to load audio'));
          };

          audio.addEventListener('canplaythrough', handleCanPlay, { once: true });
          audio.addEventListener('error', handleError, { once: true });
          audio.load();
        });

        loadingRef.current = false;
        setState('ready');
        onStoryGenerated(location.id, story, audioUrl);
        onStoryStateChange('ready', story);
      } catch (error) {
        loadingRef.current = false;
        setState('idle');
        if (error instanceof Error && error.name !== 'AbortError') {
          console.error('Error loading audio:', error);
          setIsPlaying(false);
          showError('Failed to load audio. Please check your connection and try again.');
          cleanupAudio();
        }
      }
    } catch (error) {
      if (!abortControllerRef.current?.signal.aborted) {
        console.error('Story generation error:', error);
        setState('idle');
        onStoryStateChange('idle');
        loadingRef.current = false;
        showError('Failed to generate story. Please try again.');
        cleanupAudio();
      }
    }
  };

  return {
    audioRef,
    state,
    isPlaying,
    handlePlayPause,
    currentStory: storyRef.current,
    error
  };
}
import type { MapContext } from '../../types';

// Query type detection
export function isLocationQuery(query: string): boolean {
  // Common location indicators
  const locationPatterns = [
    /\b(in|at|near|around)\b/i,  // Prepositions
    /\b(street|road|avenue|blvd|plaza|square|park)\b/i,  // Address elements
    /\b(city|town|village|county|state|country)\b/i,  // Geographic units
    /^[A-Z][a-z]+(?:[\s-][A-Z][a-z]+)*$/,  // Proper nouns (e.g., "New York")
    /^\d+\s+[A-Za-z\s]+$/  // Street addresses
  ];

  return locationPatterns.some(pattern => pattern.test(query));
}

export function isDiscoveryQuery(query: string): boolean {
  // Common discovery/category indicators
  const discoveryPatterns = [
    /\b(find|show|where|what|best|good|top|great|recommend)\b/i,  // Action words
    /\b(restaurants?|cafes?|shops?|stores?|markets?|museums?|parks?|attractions?|spots?|places?|locations?)\b/i,  // Place categories
    /\b(things?\s+to\s+do|places?\s+to\s+go|activities|events)\b/i,  // Activities
    /\b(food|coffee|drinks?|shopping|entertainment|sports?|arts?|picnic)\b/i  // Categories
  ];

  // Also consider simple category searches (e.g., "coffee shops", "picnic spots")
  const simplePatterns = [
    /^[a-z\s]+(shops?|spots?|places?|locations?)$/i  // Simple category + location type
  ];

  return discoveryPatterns.some(pattern => pattern.test(query)) ||
         simplePatterns.some(pattern => pattern.test(query));
}

export function isSimpleLocationSearch(query: string): boolean {
  // Check if the query is a simple location name without discovery terms
  return isLocationQuery(query) && !isDiscoveryQuery(query);
}

export function cleanOpenAIResponse(content: string): string {
  return content.replace(/```json\n?|\n?```/g, '').trim();
}

export function isWithinBounds(
  coordinates: [number, number],
  bounds?: [[number, number], [number, number]]
): boolean {
  if (!bounds) return true;
  
  const [lng, lat] = coordinates;
  const [[west, south], [east, north]] = bounds;
  
  // Add a small buffer (about 10%) to the bounds
  const lngBuffer = (east - west) * 0.1;
  const latBuffer = (north - south) * 0.1;
  
  return lng >= (west - lngBuffer) && 
         lng <= (east + lngBuffer) && 
         lat >= (south - latBuffer) && 
         lat <= (north + latBuffer);
}

export function expandBounds(
  bounds: [[number, number], [number, number]], 
  attempt: number
): [[number, number], [number, number]] {
  const [[west, south], [east, north]] = bounds;
  
  // Calculate expansion factor based on attempt number (1x, 2x, 4x)
  const expansionFactor = Math.pow(2, attempt - 1);
  
  // Calculate the current width and height
  const width = east - west;
  const height = north - south;
  
  // Calculate how much to expand in each direction
  const lngExpansion = (width * (expansionFactor - 1)) / 2;
  const latExpansion = (height * (expansionFactor - 1)) / 2;
  
  return [
    [west - lngExpansion, south - latExpansion],
    [east + lngExpansion, north + latExpansion]
  ];
}

export function calculateSearchRadius(bounds: [[number, number], [number, number]], attempt: number = 1): number {
  const [[west, south], [east, north]] = bounds;
  
  // Convert degrees to radians
  const toRad = (deg: number) => deg * Math.PI / 180;
  
  // Earth's radius in kilometers
  const R = 6371;
  
  // Calculate center point
  const centerLat = (north + south) / 2;
  const centerLng = (east + west) / 2;
  
  // Calculate corner point
  const cornerLat = north;
  const cornerLng = east;
  
  // Haversine formula
  const dLat = toRad(cornerLat - centerLat);
  const dLon = toRad(cornerLng - centerLng);
  const lat1 = toRad(centerLat);
  const lat2 = toRad(cornerLat);
  
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
           Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  
  // Calculate base radius
  const baseRadius = R * c;
  
  // Apply multiplier based on attempt number (1x, 2x, 4x)
  const multiplier = Math.pow(2, attempt - 1);
  
  return baseRadius * multiplier;
}

export function getLoadingMessage(query: string, attempt?: number): string {
  if (!query) return "Searching for interesting places...";
  
  if (attempt) {
    if (attempt === 2) {
      return "Interesting search request.\nI'm going to take a look a bit further afield.";
    } else if (attempt === 3) {
      return "Wow, I'm not finding much nearby.\nI'm going to make one more attempt.";
    }
  }
  
  return `Finding ${query.toLowerCase().replace(/show me |find |what |where /i, '')}...`;
}
import { MAPBOX_API, MAPBOX_TOKEN } from './config';
import type { MapContext } from '../../types';

interface GeocodeResponse {
  features: Array<{
    center: [number, number];
    place_name: string;
    relevance: number;
    text: string;
    place_type: string[];
    bbox?: [number, number, number, number];
    context?: Array<{
      id: string;
      text: string;
      wikidata?: string;
      short_code?: string;
    }>;
  }>;
}

export async function geocodeAddress(location: {
  name: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
}, mapContext?: MapContext): Promise<[number, number] | null> {
  if (!MAPBOX_TOKEN) {
    throw new Error('Mapbox access token is missing');
  }

  // Build a more specific search query
  const searchParts = [];
  
  // Add specific address if available
  if (location.address) {
    searchParts.push(location.address);
  }
  
  // Add the location name if no specific address
  if (!location.address) {
    searchParts.push(location.name);
  }
  
  // Add city, state, country for better context
  if (location.city) searchParts.push(location.city);
  if (location.state) searchParts.push(location.state);
  if (location.country) searchParts.push(location.country);
  
  const searchQuery = searchParts.join(', ');
  
  // Add proximity if we have map context
  const proximityParam = mapContext ? 
    `&proximity=${mapContext.center[0]},${mapContext.center[1]}` : '';

  try {
    console.log('Geocoding query:', searchQuery);
    
    const response = await fetch(
      `${MAPBOX_API}/geocoding/v5/mapbox.places/${encodeURIComponent(searchQuery)}.json?access_token=${MAPBOX_TOKEN}&limit=5${proximityParam}`
    );

    if (!response.ok) {
      throw new Error(`Mapbox API error: ${response.status} ${response.statusText}`);
    }

    const data: GeocodeResponse = await response.json();
    console.log('Geocoding response:', data);
    
    if (!data.features?.length) {
      console.log('No features found for query:', searchQuery);
      return null;
    }

    // Find the best match considering both relevance and proximity
    const bestMatch = data.features.reduce((best, current) => {
      // Increase score for more specific place types
      const placeTypeScore = current.place_type.includes('poi') ? 0.2 : 
                            current.place_type.includes('address') ? 0.1 : 0;
      
      // Calculate current score based on relevance and place type
      const currentScore = current.relevance + placeTypeScore;
      
      // If we have a map context, consider distance from center
      if (mapContext) {
        const [lng, lat] = current.center;
        const [centerLng, centerLat] = mapContext.center;
        const distance = Math.sqrt(
          Math.pow(lng - centerLng, 2) + Math.pow(lat - centerLat, 2)
        );
        return currentScore > best.score ? { feature: current, score: currentScore } :
               best;
      }
      
      return currentScore > best.score ? { feature: current, score: currentScore } :
             best;
    }, { feature: data.features[0], score: 0 });

    console.log('Selected match:', {
      place_name: bestMatch.feature.place_name,
      relevance: bestMatch.feature.relevance,
      score: bestMatch.score,
      coordinates: bestMatch.feature.center
    });

    return bestMatch.feature.center;
  } catch (error) {
    console.error('Geocoding error:', error);
    return null;
  }
}

export async function geocodeLocation(query: string): Promise<{ coordinates: [number, number]; confidence: number; placeName: string; bbox?: [number, number, number, number] } | null> {
  if (!MAPBOX_TOKEN) {
    throw new Error('Mapbox access token is missing');
  }

  try {
    console.log('Geocoding query:', query);
    
    // For single-word queries, we want to be more strict about matches
    const isSingleWord = query.trim().split(/\s+/).length === 1;
    
    const response = await fetch(
      `${MAPBOX_API}/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${MAPBOX_TOKEN}&limit=5&types=country,region,place,district`
    );

    if (!response.ok) {
      throw new Error(`Mapbox API error: ${response.status} ${response.statusText}`);
    }

    const data: GeocodeResponse = await response.json();
    console.log('Geocoding response:', data);
    
    if (!data.features?.length) {
      console.log('No features found for query:', query);
      return null;
    }

    // Find the best match
    const bestMatch = data.features.find(feature => {
      if (isSingleWord) {
        // For single words, require close match of the primary name
        return feature.text.toLowerCase().includes(query.toLowerCase()) ||
               query.toLowerCase().includes(feature.text.toLowerCase());
      }
      // For multi-word queries, check if all words are present in the place name
      const queryWords = query.toLowerCase().split(/\s+/);
      const placeWords = feature.place_name.toLowerCase();
      return queryWords.every(word => placeWords.includes(word));
    });

    if (!bestMatch) {
      return null;
    }

    console.log('Selected match:', {
      place_name: bestMatch.place_name,
      confidence: bestMatch.relevance,
      text: bestMatch.text,
      bbox: bestMatch.bbox
    });

    return {
      coordinates: bestMatch.center,
      confidence: bestMatch.relevance,
      placeName: bestMatch.place_name,
      bbox: bestMatch.bbox
    };
  } catch (error) {
    console.error('Geocoding error:', error);
    return null;
  }
}

export async function reverseGeocode(coordinates: [number, number], zoom: number): Promise<{ name: string; type: string }> {
  if (!MAPBOX_TOKEN) {
    throw new Error('Mapbox access token is missing');
  }

  try {
    const [lng, lat] = coordinates;
    const response = await fetch(
      `${MAPBOX_API}/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${MAPBOX_TOKEN}&types=place,locality,neighborhood,address,poi`
    );

    if (!response.ok) {
      throw new Error(`Mapbox API error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Reverse geocoding response:', data);
    
    if (!data.features?.length) {
      throw new Error('No location found at these coordinates');
    }

    const feature = getFeatureByZoomLevel(data.features, zoom);
    if (!feature) {
      throw new Error('Could not determine location details');
    }

    return {
      name: feature.text,
      type: feature.place_type[0]
    };
  } catch (error) {
    console.error('Error reverse geocoding:', error instanceof Error ? error.message : 'Unknown error');
    throw new Error('Could not identify this location. Please try a different spot.');
  }
}

function getFeatureByZoomLevel(features: any[], zoom: number): any {
  const ZOOM_RANGES = {
    country: { min: 0, max: 3.4 },
    region: { min: 3.5, max: 6.5 },
    place: { min: 6.6, max: 11 },
    neighborhood: { min: 11.1, max: 13 },
    poi: { min: 13.1, max: 16 }
  };

  const targetTypes = Object.entries(ZOOM_RANGES)
    .filter(([_, range]) => zoom >= range.min && zoom <= range.max)
    .map(([type]) => type);

  let feature = features.find(f => targetTypes.includes(f.place_type[0]));

  if (!feature) {
    if (zoom > 11) {
      feature = features.find(f => ['poi', 'venue'].includes(f.place_type[0]));
    }
    else if (zoom > 6) {
      feature = features.find(f => ['place', 'locality'].includes(f.place_type[0]));
    }
    else {
      feature = features.find(f => ['country', 'region'].includes(f.place_type[0]));
    }
  }

  if (!feature) {
    const preferredOrder = ['poi', 'venue', 'address', 'neighborhood', 'locality', 'place', 'region', 'country'];
    
    for (const type of preferredOrder) {
      feature = features.find(f => f.place_type[0] === type);
      if (feature) break;
    }
  }

  return feature || features[0];
}
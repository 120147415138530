import { openai, OPENAI_CONFIG } from './config';
import { cleanOpenAIResponse } from './utils';
import { geocodeAddress } from './geocoding';
import { getGeographicContext } from './location';
import type { Location, MapContext } from '../../types';
import { SEARCH_LOCATIONS_PROMPT } from './prompts';

export const searchLocations = async (
  query: string, 
  mapContext: MapContext,
  onAttempt?: (attempt: number) => void
): Promise<Location[]> => {
  let attempt = 1;
  let maxAttempts = 3;
  let currentBounds = mapContext.bounds;
  let validLocations: Location[] = [];

  while (attempt <= maxAttempts && validLocations.length < 3) {
    try {
      onAttempt?.(attempt);

      const geographicContext = await getGeographicContext(
        mapContext.center,
        currentBounds || [
          [mapContext.center[0] - 0.1, mapContext.center[1] - 0.1],
          [mapContext.center[0] + 0.1, mapContext.center[1] + 0.1]
        ]
      );

      console.log('Search attempt', attempt, 'with context:', geographicContext);

      // Log the complete prompt being sent to OpenAI
      const prompt = `Accuracy is critical in your response. Do not make up places. Find locations matching: ${query}\nGeographic context: ${geographicContext}`;
      console.log('OpenAI Search Request:', {
        systemPrompt: SEARCH_LOCATIONS_PROMPT,
        userPrompt: prompt,
        temperature: 0.0,
        model: OPENAI_CONFIG.model
      });

      const completion = await openai.chat.completions.create({
        model: OPENAI_CONFIG.model,
        messages: [{
          role: "system",
          content: SEARCH_LOCATIONS_PROMPT
        }, {
          role: "user",
          content: prompt
        }],
        temperature: 0.0,
        response_format: { type: "json_object" }
      });

      const content = completion.choices[0]?.message?.content;
      if (!content) {
        throw new Error('No suggestions received from OpenAI');
      }

      // Log the raw response from OpenAI
      console.log('OpenAI Raw Response:', content);

      let suggestions: any[];
      try {
        const cleanedContent = cleanOpenAIResponse(content);
        const parsedContent = JSON.parse(cleanedContent);
        
        if (!parsedContent.locations || !Array.isArray(parsedContent.locations)) {
          throw new Error('Invalid response format from OpenAI');
        }
        
        suggestions = parsedContent.locations;
        console.log('OpenAI Parsed Suggestions:', suggestions);
      } catch (error) {
        console.error('Failed to parse OpenAI response:', content);
        throw new Error('Invalid response format from OpenAI');
      }

      // Process suggestions in sequence to maintain order
      for (const suggestion of suggestions) {
        try {
          if (!suggestion.name || !suggestion.description) {
            console.warn('Invalid location data:', suggestion);
            continue;
          }

          const coordinates = await geocodeAddress(suggestion, mapContext);
          if (!coordinates) {
            console.warn('Could not geocode location:', suggestion.name);
            continue;
          }

          const location: Location = {
            id: `loc-${Date.now()}-${validLocations.length}`,
            name: suggestion.name,
            description: suggestion.description,
            coordinates,
            address: suggestion.address || undefined,
            city: suggestion.city || undefined,
            state: suggestion.state || undefined,
            country: suggestion.country || undefined,
            postalCode: suggestion.postalCode || undefined
          };

          validLocations.push(location);
          console.log('Added valid location:', location.name, 'at coordinates:', coordinates);

          // Break early if we have enough locations
          if (validLocations.length >= 5) {
            break;
          }
        } catch (error) {
          console.warn('Error processing location:', error);
        }
      }

      // If we have any locations, return them
      if (validLocations.length > 0) {
        console.log('Found valid locations:', validLocations.length);
        return validLocations;
      }

      // If this is our last attempt, return whatever we have
      if (attempt === maxAttempts) {
        return validLocations;
      }

      // Otherwise, expand the search area and try again
      if (currentBounds) {
        const [[west, south], [east, north]] = currentBounds;
        const expandFactor = 1.5;
        const lngDiff = (east - west) * (expandFactor - 1) / 2;
        const latDiff = (north - south) * (expandFactor - 1) / 2;
        
        currentBounds = [
          [west - lngDiff, south - latDiff],
          [east + lngDiff, north + latDiff]
        ];
        console.log('Expanding search bounds for next attempt:', currentBounds);
      }

      attempt++;
    } catch (error) {
      console.error('Error searching locations:', error);
      
      if (error instanceof Error) {
        if (error.message.includes('rate limits exceeded')) {
          throw new Error('Search limit reached. Please try again in a moment.');
        } else if (error.message.includes('API key')) {
          throw new Error('Service configuration error. Please try again later.');
        } else if (error.message.includes('Invalid response format')) {
          throw new Error('Unable to process search results. Please try again.');
        } else {
          throw error;
        }
      }
      
      throw new Error('An unexpected error occurred. Please try again.');
    }
  }

  // Return whatever valid locations we found
  if (validLocations.length > 0) {
    console.log('Returning results:', validLocations.length, 'locations');
    return validLocations;
  }

  throw new Error('No locations found. Try adjusting your search terms or zooming to a different area.');
};
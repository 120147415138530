import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { MapState } from '../types';
import mapboxgl from 'mapbox-gl';

// Set initial view to continental US with appropriate zoom
const DEFAULT_CENTER: [number, number] = [-98.5795, 39.8283];
const INITIAL_ZOOM = 4.5;

interface MapHistoryEntry {
  center: [number, number];
  bounds: [[number, number], [number, number]];
  zoom: number;
}

export const useMapStore = create<MapState>()(
  persist(
    (set, get) => ({
      locations: [],
      selectedLocation: null,
      isDrawerOpen: false,
      query: '',
      loading: false,
      loadingMessage: '',
      error: null,
      mapCenter: DEFAULT_CENTER,
      map: null,
      mapHistory: [{
        center: DEFAULT_CENTER,
        bounds: [[-180, -85], [180, 85]],
        zoom: INITIAL_ZOOM
      }],
      currentHistoryIndex: 0,
      lastSearchParams: null,
      
      setLocations: (locations) => set({ locations }),
      
      setSelectedLocation: (location) => set({ selectedLocation: location }),
      
      setDrawerOpen: (isOpen) => set({ isDrawerOpen: isOpen }),
      
      setQuery: (query) => set({ query }),
      
      setLoading: (loading) => set({ loading }),
      
      setLoadingMessage: (message) => set({ loadingMessage: message }),
      
      setError: (error) => set({ error }),
      
      setMapCenter: (center) => {
        const { mapHistory, currentHistoryIndex, map } = get();
        if (!map) return;

        const bounds = map.getBounds();
        if (!bounds) return;

        const zoom = map.getZoom();
        const currentEntry = {
          center,
          bounds: [
            [bounds.getWest(), bounds.getSouth()],
            [bounds.getEast(), bounds.getNorth()]
          ] as [[number, number], [number, number]],
          zoom
        };
        
        // Only add to history if position actually changed
        const lastEntry = mapHistory[currentHistoryIndex];
        if (lastEntry && 
            lastEntry.center[0] === center[0] && 
            lastEntry.center[1] === center[1] &&
            lastEntry.zoom === zoom) {
          return;
        }

        // Remove any forward history when adding new entry
        const newHistory = mapHistory.slice(0, currentHistoryIndex + 1);
        newHistory.push(currentEntry);
        
        set({ 
          mapCenter: center,
          mapHistory: newHistory,
          currentHistoryIndex: newHistory.length - 1
        });
      },
      
      setMap: (map: mapboxgl.Map | null) => set({ map }),

      setLastSearchParams: (params) => set({ lastSearchParams: params }),
      
      updateLocationStory: (locationId: string, story: string, audioUrl: string) => {
        const { locations, selectedLocation } = get();
        
        const updatedLocations = locations.map(location =>
          location.id === locationId
            ? { ...location, story, audioUrl }
            : location
        );
        
        const updatedSelectedLocation = selectedLocation?.id === locationId
          ? { ...selectedLocation, story, audioUrl }
          : selectedLocation;
        
        set({ 
          locations: updatedLocations,
          selectedLocation: updatedSelectedLocation
        });
      },

      updateLocationDetails: (locationId: string, updates: Partial<Location>) => {
        const { locations, selectedLocation } = get();
        
        const updatedLocations = locations.map(location =>
          location.id === locationId
            ? { ...location, ...updates }
            : location
        );
        
        const updatedSelectedLocation = selectedLocation?.id === locationId
          ? { ...selectedLocation, ...updates }
          : selectedLocation;
        
        set({ 
          locations: updatedLocations,
          selectedLocation: updatedSelectedLocation
        });
      },
      
      clearLocation: () => {
        set({ 
          locations: [],
          selectedLocation: null,
          isDrawerOpen: false,
          query: '',
          loading: false,
          loadingMessage: '',
          lastSearchParams: null
        });
      },

      goBack: () => {
        const { mapHistory, currentHistoryIndex, map, isDrawerOpen } = get();
        if (currentHistoryIndex > 0 && map) {
          const previousEntry = mapHistory[currentHistoryIndex - 1];
          
          // Clear any existing locations and close drawer when going back
          set({
            locations: [],
            selectedLocation: null,
            isDrawerOpen: false,
            query: '',
            lastSearchParams: null,
            currentHistoryIndex: currentHistoryIndex - 1,
            mapCenter: previousEntry.center
          });

          map.fitBounds(previousEntry.bounds, {
            padding: { top: 50, bottom: isDrawerOpen ? window.innerHeight * 0.6 : 50, left: 50, right: 50 }
          });
        }
      },

      retrySearch: async () => {
        const { lastSearchParams, map } = get();
        if (!lastSearchParams || !map) return;

        const { searchFn, query } = lastSearchParams;
        const bounds = map.getBounds();
        if (!bounds) return;

        try {
          set({ loading: true, error: null });
          const results = await searchFn(query, {
            center: [map.getCenter().lng, map.getCenter().lat],
            bounds: [
              [bounds.getWest(), bounds.getSouth()],
              [bounds.getEast(), bounds.getNorth()]
            ],
            zoom: map.getZoom()
          });

          set({ 
            locations: results,
            isDrawerOpen: true,
            loading: false
          });
        } catch (error) {
          set({ 
            error: error instanceof Error ? error.message : 'Search failed',
            loading: false
          });
        }
      },

      fitToLocations: () => {
        const { locations, map, isDrawerOpen } = get();
        if (!map || locations.length === 0) return;

        const bounds = new mapboxgl.LngLatBounds();
        locations.forEach(location => {
          bounds.extend(location.coordinates);
        });

        const bottomPadding = isDrawerOpen ? window.innerHeight * 0.6 : 50;
        const minPadding = Math.min(window.innerWidth * 0.1, 100);

        map.fitBounds(bounds, {
          padding: {
            top: minPadding,
            bottom: bottomPadding,
            left: minPadding,
            right: minPadding
          },
          maxZoom: 16
        });
      }
    }),
    {
      name: 'terratales-storage',
      partialize: (state) => ({
        mapHistory: [{
          center: DEFAULT_CENTER,
          bounds: [[-180, -85], [180, 85]],
          zoom: INITIAL_ZOOM
        }],
        currentHistoryIndex: 0
      })
    }
  )
);
import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { useMapStore } from '../../store/mapStore';
import { useMapHandlers } from './hooks/useMapHandlers';
import { useMarkers } from './hooks/useMarkers';
import { setupNavigationControls } from './controls/NavigationControls';
import { setupLayerControls } from './controls/LayerControls';
import { INITIAL_CENTER, INITIAL_ZOOM, MIN_ZOOM, MAX_ZOOM, LAYER_CONFIGS } from './constants';

export function Map() {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const geolocateControlRef = useRef<mapboxgl.GeolocateControl | null>(null);
  const [currentZoom, setCurrentZoom] = useState<number>(INITIAL_ZOOM);
  
  const { 
    locations,
    selectedLocation, 
    setSelectedLocation, 
    setMapCenter,
    setMap,
    setDrawerOpen,
    query
  } = useMapStore();

  const { handleClick, handleDoubleClick } = useMapHandlers();

  // Setup click handlers
  useEffect(() => {
    if (!map.current) return;

    const clickHandler = (e: mapboxgl.MapMouseEvent) => {
      handleClick(e);
    };

    const dblClickHandler = (e: mapboxgl.MapMouseEvent) => {
      handleDoubleClick(e);
    };

    map.current.on('click', clickHandler);
    map.current.on('dblclick', dblClickHandler);

    return () => {
      map.current?.off('click', clickHandler);
      map.current?.off('dblclick', dblClickHandler);
    };
  }, [handleClick, handleDoubleClick]);

  useEffect(() => {
    const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
    if (!mapContainer.current || !mapboxToken) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: INITIAL_CENTER,
      zoom: INITIAL_ZOOM,
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      accessToken: mapboxToken,
      clickTolerance: 3,
      projection: 'mercator'
    });

    // Immediately set initial center
    setMapCenter(INITIAL_CENTER);

    map.current.on('load', () => {
      setMap(map.current);
      
      if (geolocateControlRef.current) {
        geolocateControlRef.current.trigger();
      }
    });

    const controlsContainer = document.createElement('div');
    controlsContainer.className = 'absolute top-20 left-[calc(40px-1.25rem)] flex flex-col gap-1.5';
    mapContainer.current.appendChild(controlsContainer);

    geolocateControlRef.current = setupNavigationControls(
      map.current,
      controlsContainer,
      ({ latitude, longitude }) => {
        if (map.current) {
          map.current.flyTo({
            center: [longitude, latitude],
            zoom: 9,
            essential: true,
            duration: 2000
          });
          
          // Record the geolocation in map history
          const bounds = map.current.getBounds();
          setMapCenter([longitude, latitude]);
        }
      }
    );

    setupLayerControls(map.current, mapContainer.current, LAYER_CONFIGS);
    
    // Update center on any map movement
    const updateMapCenter = () => {
      if (map.current) {
        const center = map.current.getCenter();
        setMapCenter([center.lng, center.lat]);
      }
    };

    // Add all movement-related event listeners
    map.current.on('moveend', updateMapCenter);
    map.current.on('dragend', updateMapCenter);
    map.current.on('zoomend', updateMapCenter);
    map.current.on('rotateend', updateMapCenter);
    map.current.on('pitchend', updateMapCenter);

    map.current.on('zoom', () => {
      if (map.current) {
        setCurrentZoom(Math.round(map.current.getZoom() * 10) / 10);
      }
    });

    return () => {
      if (map.current) {
        map.current.off('moveend', updateMapCenter);
        map.current.off('dragend', updateMapCenter);
        map.current.off('zoomend', updateMapCenter);
        map.current.off('rotateend', updateMapCenter);
        map.current.off('pitchend', updateMapCenter);
      }
      map.current?.remove();
      setMap(null);
      geolocateControlRef.current = null;
    };
  }, []);

  // Update cursor style based on interaction state
  useEffect(() => {
    if (!map.current) return;
    map.current.getCanvas().style.cursor = query ? 'default' : 'pointer';
  }, [query]);

  // Setup markers
  useMarkers(map.current, locations, selectedLocation, (location) => {
    setSelectedLocation(location);
    setDrawerOpen(true);
  });

  return (
    <>
      <div ref={mapContainer} className="w-full h-full" />
      <div className="absolute bottom-0 left-0 mb-5 ml-5 text-xs text-gray-500 pointer-events-none select-none">
        Zoom: {currentZoom}
      </div>
    </>
  );
}
import { openai, OPENAI_CONFIG } from './config';
import type { Location, StoryStyle } from '../../types';
import { LOCATION_STORY_PROMPT } from './prompts';
import { getGeographicContext } from './location';

const getStylePrompt = (style: StoryStyle = 'fact') => {
  switch (style) {
    case 'fact':
      return 'Focus on historical accuracy and factual details. The story should be strictly based on verified historical records and real events.';
    case 'fantasy':
      return 'Create an imaginative story that weaves magical and fantastical elements into the historical narrative, while maintaining the essence of the location.';
    default:
      return 'Blend historical facts with creative storytelling, maintaining a balance between accuracy and engaging narrative elements.';
  }
};

export async function playLocationStory(
  location: Location,
  onStageChange?: (stage: 'story' | 'audio', story?: string) => void
): Promise<{ audioUrl: string; story: string }> {
  try {
    const context = await getGeographicContext(
      location.coordinates,
      [
        [location.coordinates[0] - 0.01, location.coordinates[1] - 0.01],
        [location.coordinates[0] + 0.01, location.coordinates[1] + 0.01]
      ]
    );

    // Build the prompt with all available context
    let prompt = `Tell me about ${location.name}${context ? ` ${context}` : ''}`;
    
    if (location.timeframe) {
      prompt += ` during ${location.timeframe}`;
    }
    
    if (location.topics?.length) {
      const topicsStr = location.topics.join(' and ');
      prompt += `, focusing specifically on ${topicsStr}`;
    }

    // Use the actual style or default to 'fact'
    const storyStyle = location.storyStyle || 'fact';

    // Add style guidance
    prompt += '. ' + getStylePrompt(storyStyle);

    // Adjust temperature based on story style
    const temperature = storyStyle === 'fact' ? 0.2 : 
                       storyStyle === 'fantasy' ? 0.7 : 
                       0.4;

    console.log('OpenAI Story Request:', {
      systemPrompt: LOCATION_STORY_PROMPT,
      userPrompt: prompt,
      temperature,
      model: OPENAI_CONFIG.model,
      storyStyle
    });

    const completion = await openai.chat.completions.create({
      model: OPENAI_CONFIG.model,
      messages: [
        {
          role: "system",
          content: LOCATION_STORY_PROMPT
        },
        {
          role: "user",
          content: prompt
        }
      ],
      temperature
    });

    const story = completion.choices[0]?.message?.content;
    if (!story) {
      throw new Error('No story generated');
    }

    onStageChange?.('story', story);
    onStageChange?.('audio');

    const speech = await openai.audio.speech.create({
      model: OPENAI_CONFIG.speechModel,
      voice: OPENAI_CONFIG.voice,
      input: story,
    });

    const audioBlob = new Blob([await speech.arrayBuffer()], { type: 'audio/mpeg' });
    const audioUrl = URL.createObjectURL(audioBlob);

    return { audioUrl, story };
  } catch (error) {
    console.error('Story generation error:', error);
    throw error;
  }
}
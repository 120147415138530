import { MAPBOX_API, MAPBOX_TOKEN } from './config';
import { calculateSearchRadius } from './utils';

export async function getGeographicContext(
  coordinates: [number, number],
  bounds: [[number, number], [number, number]]
): Promise<string> {
  if (!MAPBOX_TOKEN) {
    throw new Error('Mapbox access token is missing');
  }

  try {
    const response = await fetch(
      `${MAPBOX_API}/geocoding/v5/mapbox.places/${coordinates[0]},${coordinates[1]}.json?access_token=${MAPBOX_TOKEN}`
    );

    if (!response.ok) {
      throw new Error(`Mapbox API error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Geographic context response:', data);
    
    if (!data.features?.length) {
      return '';
    }

    const city = data.features.find((f: any) => f.place_type[0] === 'place')?.text || '';
    const region = data.features.find((f: any) => f.place_type[0] === 'region')?.text || '';
    const country = data.features.find((f: any) => f.place_type[0] === 'country')?.text || '';

    const radius = calculateSearchRadius(bounds);
    const parts = [city, region, country].filter(Boolean);
    
    if (parts.length === 0) return '';
    
    const context = `Within a ${Math.round(radius)}km radius of ${parts.join(', ')}`;
    console.log('Generated geographic context:', context);
    return context;
  } catch (error) {
    console.error('Error getting geographic context:', error);
    return '';
  }
}
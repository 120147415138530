import { useState } from 'react';
import { useMapStore } from '../../store/mapStore';
import { 
  getLoadingMessage
} from '../../services/api/utils';
import { searchLocations } from '../../services/api/search';
import { geocodeLocation } from '../../services/api/geocoding';
import { MagnifyingGlassIcon, XMarkIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { LoadingSpinner } from '../UI/LoadingSpinner';
import { SearchConfirmDialog } from '../UI/SearchConfirmDialog';

const MIN_ZOOM = 3;
const MAX_ZOOM = 16;
const CONFIDENCE_THRESHOLD = 0.8;

export function SearchBar() {
  const [inputValue, setInputValue] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [pendingSearch, setPendingSearch] = useState<{
    coordinates: [number, number];
    placeName: string;
    searchTerm: string;
    bbox?: [number, number, number, number];
  } | null>(null);

  const { 
    setLocations, 
    setLoading,
    setLoadingMessage,
    setError, 
    setDrawerOpen, 
    mapCenter,
    loading,
    setQuery,
    map,
    goBack,
    mapHistory,
    currentHistoryIndex,
    setLastSearchParams,
    fitToLocations
  } = useMapStore();

  const handleSearch = async (searchTerm: string, useLocation?: boolean) => {
    try {
      setLoading(true);
      setLoadingMessage(getLoadingMessage(searchTerm));

      if (!map) {
        throw new Error('Please wait for the map to fully load and try again.');
      }

      const bounds = map.getBounds();
      if (!bounds) {
        throw new Error('Please wait for the map to fully load and try again.');
      }

      if (useLocation === true && pendingSearch) {
        // If we have a bounding box, use it for better context
        if (pendingSearch.bbox) {
          map.fitBounds([
            [pendingSearch.bbox[0], pendingSearch.bbox[1]], // Southwest coordinates
            [pendingSearch.bbox[2], pendingSearch.bbox[3]]  // Northeast coordinates
          ], {
            padding: { top: 50, bottom: 50, left: 50, right: 50 }
          });
        } else {
          map.flyTo({
            center: pendingSearch.coordinates,
            zoom: Math.min(10, MAX_ZOOM),
            essential: true
          });
        }
        setLocations([]);
        setDrawerOpen(false);
        setLastSearchParams(null);
        setPendingSearch(null);
        return;
      }

      const currentCenter = map.getCenter();
      const currentBounds = [
        [bounds.getWest(), bounds.getSouth()],
        [bounds.getEast(), bounds.getNorth()]
      ] as [[number, number], [number, number]];

      setLastSearchParams({
        searchFn: searchLocations,
        query: searchTerm
      });

      const results = await searchLocations(
        searchTerm,
        {
          center: [currentCenter.lng, currentCenter.lat],
          bounds: currentBounds,
          zoom: Math.min(Math.max(map.getZoom(), MIN_ZOOM), MAX_ZOOM)
        },
        (attempt) => {
          setLoadingMessage(getLoadingMessage(searchTerm, attempt));
        }
      );

      setLocations(results);
      setDrawerOpen(true);
      fitToLocations();
      setPendingSearch(null);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
      setLoadingMessage("");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const trimmedInput = inputValue.trim();
    
    if (!trimmedInput || !map) {
      setError('Please enter a search term');
      return;
    }

    try {
      setLoading(true);
      setLoadingMessage(getLoadingMessage(trimmedInput));
      setError(null);
      setQuery(trimmedInput);

      // Check if this is a single word search
      const isSingleWord = trimmedInput.split(/\s+/).length === 1;

      // Try geocoding first
      const result = await geocodeLocation(trimmedInput);
        
      if (result) {
        if (isSingleWord || result.bbox) {
          // For single words or results with bbox (like countries), show confirmation dialog
          setPendingSearch({
            coordinates: result.coordinates,
            placeName: result.placeName,
            searchTerm: trimmedInput,
            bbox: result.bbox
          });
          setShowConfirmDialog(true);
          setLoading(false);
          return;
        } else if (result.confidence >= CONFIDENCE_THRESHOLD) {
          // Multi-word search with high confidence - go directly to location
          map.flyTo({
            center: result.coordinates,
            zoom: Math.min(10, MAX_ZOOM),
            essential: true
          });
          setLocations([]);
          setDrawerOpen(false);
          setLastSearchParams(null);
        } else {
          // Low confidence - proceed with discovery search
          await handleSearch(trimmedInput);
        }
      } else {
        await handleSearch(trimmedInput);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
      setLoadingMessage("");
      setQuery('');
    }
  };

  const handleClear = () => {
    setInputValue('');
    setLocations([]);
    setDrawerOpen(false);
    setError(null);
    setQuery('');
    setPendingSearch(null);
    setShowConfirmDialog(false);
  };

  const handleBack = () => {
    handleClear();
    goBack();
  };

  const canGoBack = currentHistoryIndex > 0 && mapHistory.length > 1;

  return (
    <>
      <div className="absolute top-4 left-0 right-0 z-20">
        <div className="px-4 w-full">
          <div className="flex items-center gap-3">
            <button
              onClick={handleBack}
              disabled={!canGoBack}
              className={`h-10 w-10 flex items-center justify-center rounded-lg shadow-lg bg-white transition-all duration-200 ${
                canGoBack 
                  ? 'hover:bg-gray-100 opacity-100 cursor-pointer' 
                  : 'opacity-30 cursor-not-allowed'
              }`}
              title={canGoBack ? "Go back to previous location" : "No previous location"}
            >
              <ArrowLeftIcon className="h-5 w-5 text-gray-600" />
            </button>

            <form onSubmit={handleSubmit} className="flex-1 max-w-[calc(100%-6rem-25px)]">
              <div className="relative">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Search for a place or discover interesting locations..."
                  className="w-full h-10 pl-4 pr-20 rounded-lg shadow-lg border-0 focus:ring-2 focus:ring-[#346DFF] text-sm"
                  disabled={loading}
                />
                {inputValue && !loading && (
                  <button
                    type="button"
                    onClick={handleClear}
                    className="absolute right-12 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                )}
                <button
                  type="submit"
                  disabled={!inputValue.trim() || loading}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 text-[#346DFF] hover:text-[#2855CC] disabled:text-gray-300"
                >
                  {loading ? (
                    <LoadingSpinner size="sm" />
                  ) : (
                    <MagnifyingGlassIcon className="h-5 w-5" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {showConfirmDialog && pendingSearch && (
        <SearchConfirmDialog
          placeName={pendingSearch.placeName}
          searchTerm={pendingSearch.searchTerm}
          onConfirm={(useLocation) => {
            setShowConfirmDialog(false);
            handleSearch(pendingSearch.searchTerm, useLocation);
          }}
          onClose={() => {
            setShowConfirmDialog(false);
            setPendingSearch(null);
          }}
        />
      )}
    </>
  );
}
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { useMapStore } from '../../store/mapStore';
import { useEffect, useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { StoryPlayer } from '../Story/StoryPlayer';
import { GoogleMapsLink } from '../UI/GoogleMapsLink';
import type { Location } from '../../types';

export function DiscoveryDrawer() {
  const { 
    locations, 
    selectedLocation, 
    setSelectedLocation, 
    updateLocationStory,
    clearLocation,
    map,
    fitToLocations
  } = useMapStore();

  const dragRef = useRef<HTMLDivElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);

  const [{ y }, api] = useSpring(() => ({
    y: 30,
    config: { tension: 300, friction: 30 }
  }));

  const bind = useDrag(
    ({ movement: [_, my], last, velocity: [, vy] }) => {
      const containerHeight = window.innerHeight;
      const currentY = (my / containerHeight) * 100;
      let newY = 30 + currentY;
      newY = Math.max(10, Math.min(70, newY));

      if (last) {
        if (vy > 0.5) {
          newY = 70;
        } else if (vy < -0.5) {
          newY = 10;
        } else {
          const snapPoints = [10, 30, 70];
          newY = snapPoints.reduce((prev, curr) => 
            Math.abs(curr - newY) < Math.abs(prev - newY) ? curr : prev
          );
        }
      }

      api.start({ y: newY });
    },
    {
      from: () => [0, (y.get() / 100) * window.innerHeight],
      bounds: { top: -window.innerHeight * 0.9, bottom: window.innerHeight * 0.3 },
      rubberband: true,
      preventScroll: true,
      filterTaps: true
    }
  );

  const handleLocationSelect = (loc: Location) => {
    setSelectedLocation(loc);
    if (map) {
      map.flyTo({
        center: loc.coordinates,
        zoom: 14,
        essential: true
      });
    }
  };

  useEffect(() => {
    api.start({ y: 30 });
  }, [api]);

  return (
    <animated.div 
      ref={drawerRef}
      style={{
        transform: y.to(y => `translateY(${y}%)`),
        height: y.to(y => `${100 - y}%`),
        touchAction: 'none'
      }}
      className="fixed bottom-0 left-0 right-0 bg-white rounded-t-xl shadow-lg z-40 pointer-events-auto"
    >
      <div className="absolute top-4 right-4 z-50">
        <button
          onClick={clearLocation}
          className="p-2 rounded-full hover:bg-gray-100 transition-colors"
        >
          <XMarkIcon className="w-6 h-6 text-gray-500" />
        </button>
      </div>
      
      <div 
        ref={dragRef}
        {...bind()}
        className="w-full h-6 cursor-grab active:cursor-grabbing bg-white rounded-t-xl flex items-center justify-center touch-none"
      >
        <div className="w-12 h-1 bg-gray-300 rounded-full" />
      </div>
      
      <div className="overflow-y-auto h-[calc(100%-24px)] bg-white">
        <div className="drawer-content pb-36">
          <h2 
            className="text-xl font-bold mb-4 cursor-pointer hover:text-[#346DFF] transition-colors"
            onClick={fitToLocations}
            title="Show all locations on map"
          >
            Discovered Places
          </h2>
          <div className="space-y-4">
            {locations.map(loc => (
              <div
                key={loc.id}
                className={`location-card rounded-lg shadow-md cursor-pointer transition-colors ${
                  selectedLocation?.id === loc.id ? 'bg-blue-50 border-2 border-[#346DFF]' : 'bg-white hover:bg-gray-50'
                }`}
                onClick={() => handleLocationSelect(loc)}
              >
                <div className="flex items-center gap-2">
                  <GoogleMapsLink location={loc} />
                  <h3 className="font-bold text-lg flex-1">{loc.name}</h3>
                </div>
                <p className="text-gray-600 mt-1">{loc.description}</p>
                <div className="mt-3">
                  <StoryPlayer
                    location={loc}
                    onStoryGenerated={updateLocationStory}
                    showControls={false}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </animated.div>
  );
}